import React, { useEffect, useState, useRef } from "react";
import Sidebar from "../sidebar";
import Navbar from "../navbar";
import FingerIcon from "../../images/fingerprint-svgrepo-com.svg";
import Cookies from 'js-cookie';

// toast lib ==>
import { Toast } from "primereact/toast";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primereact/resources/primereact.min.css";

// Jalali date picker ==>
// import { DatePicker } from "@react-shamsi/datepicker";
// import "@react-shamsi/calendar/dist/styles.css";
// import "@react-shamsi/datepicker/dist/styles.css";


const User = ({ userData }) => {
//   const [isLoading, setIsLoading] = useState(true);
  const [isValidCookie, setIsValidCookie] = useState(true);
  const [user, setUser] = useState([]);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [fileName, setFileName] = useState("");
  const [image, setImage] = useState(null);
  const toast = useRef(null);
  const Auth_Cookie = Cookies.get('avaAuth');
  const [InnerLoader, setInnerLoader] = useState(false);
  const [ContentLoading, setContentLoading] = useState(true);
  const [miniLoader, setMiniLoader] = useState(false);

  useEffect(() => {
    if(userData[0] != null){
       setContentLoading(false)
    }
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
        setImage(file);
        setPreviewUrl(URL.createObjectURL(file));
        setFileName(file.name);
    } else {
        setImage(null);
        setPreviewUrl(null);
        setFileName("");
    }
  };

  const UpdateUser = async (event) => {
    event.preventDefault();

    try {
        setMiniLoader(true);
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/public/api/v1/users/user.php?` +
        `FirstName=${encodeURIComponent(userData[0]['FirstName'])}&` +
          `LastName=${encodeURIComponent(userData[0]['LastName'])}&` +
          `Mobile=${encodeURIComponent(userData[0]['Mobile'])}&` +
          `NationalCode=${encodeURIComponent(userData[0]['NationalCode'])}&` +
          `BirthDate=${encodeURIComponent(userData[0]['BirthDate'])}&` +
          `Card=${encodeURIComponent(userData[0]['Card'])}&` +
          `Hesab=${encodeURIComponent(userData[0]['Hesab'])}&` +
          `Sheba=${encodeURIComponent(userData[0]['Sheba'])}`
        ,
        {
            method: "PUT",
            headers: {
              'Authorization': `Bearer ${Auth_Cookie}`,
            },
            redirect: "follow",
          },
        );

        const data = await response.json();
        if (data.status === 200) {
            toast.current.show({
                severity: "success",
                summary: "موفق",
                detail: "اطلاعات شما با موفقیت ثبت شد",
                life: 7000,
            });
            window.location.reload();
        } else {          
            toast.current.show({
                severity: "error",
                summary: "خطا",
                detail: data.message,
                life: 7000,
            });
            setMiniLoader(false)
        }

    } catch (error) {
        if (error instanceof Error) {
            console.log(error.message);
        } else {
            console.log('An unexpected error occurred');
        }
    }
  };

  const UploadFile = async (e) => {
        e.preventDefault();
        setMiniLoader(true);
        try {
          const formData = new FormData();
          formData.append('Image', image);
          formData.append('Type', 'Identify'); // Assuming email is passed as a prop

          const requestOptions = {
              method: 'POST',
              headers: {
                  'Authorization': `Bearer ${Auth_Cookie}`,
              },
              body: formData,
              redirect: 'follow'
          };

          const response = await fetch( process.env.REACT_APP_BASE_URL + '/public/api/v1/file/upload.php', requestOptions);
          const data = await response.json();
          if (data.status === 200) {
                toast.current.show({ severity: 'success', summary: 'عملیات موفق', detail: 'تصویر شما با موفقیت بارگذاری شد' , life: 7000 });
                window.location.reload();
            } else {
                toast.current.show({ severity: 'error', summary: 'خطا', detail: data.message , life: 5000 });
                setMiniLoader(false);
            }
        } catch (error) {
            console.log(error);
            setMiniLoader(false);
        }
  };    
 
  return (
    <>
        <Toast ref={toast} />
        <div className="lg:flex">
            <Sidebar />
            <div className="mainbar">
            <Navbar />
            <div className="main">
                <div className="single_page_title">
                <h1>اطلاعات و احرازهویت</h1>
                <h2>identify</h2>

                <div className="mr-auto">
                    <i className="bx bx bx-user li_icon"></i>
                </div>
                </div>


                

                {ContentLoading == true ? (
                    <>
                    <span className="content_loader mini mt-5"></span>
                    <div className="lg:grid lg:grid-cols-2 gap-5 mt-6">
                        <span className="content_loader card p-0"></span>
                        <span className="content_loader card p-0"></span>
                    </div>
                    </>
                )  : (
                    <>
                        { userData[0]['Identify'] === 0 ? (
                            <div className="hint_box flex items-center mt-5">
                            <i className="pi pi-id-card" style={{ fontSize: '1.5rem' }}></i>
                            <div className="pr-5">
                                <b className="mb-2 block">
                                {userData[0]['Message'] == null ? (
                                    <>احراز هویت شما در دست بررسی می باشد.</>
                                ) : (
                                    <>{userData[0]['Message']}</>
                                )}
                                </b>
                                <span>لطفا از تکمیل بودن اطلاعات خود اطمینان حاصل فرمایید.</span>
                            </div>
                            </div>
                        ) : userData[0]['Identify'] === 1 ? (
                            <div className="sent_image mt-5">احراز هویت شما تایید شده است</div>
                        ) : (
                            <div className="mini_loader"><div className="loader_small"></div></div>
                        )}

                        <div className="lg:grid lg:grid-cols-2 gap-5">
                            <div className="card mt-6 p-7">
                                <div className="card_title  flex items-center">
                                <span className="card_heading_text">تصویر
                                <small>(الزامی)</small>
                                </span>
                                <span className="card_heading_icon mr-auto">
                                    <img src={FingerIcon} alt="AvaPay24" width={24} />
                                </span>
                                </div>

                                <div className="p-0">
                                    {userData && userData[0] && userData[0]["Image"] == null ? (
                                        <form action="#" onSubmit={UploadFile}>
                                            <div className="IdentifyHint">
                                                <span className='IdentifyTitle' >راهنما گرفتن عکس</span>
                                                <ul>
                                                    <li>ابتدا فایل ضمیمه شده را دانلود کرده و پرینت بگیرید. سپس برگه پرینت شده و امضا شده را در دست راست خود نگه دارید به طوری که متن به وضوح قابل خواندن باشد. </li>
                                                    <li>کارت ملی و کارت بانکی خود را در دست چپ خود نگه دارید.</li>
                                                    <li>مطمئن شوید که چهره شما، متن امضا شده، کارت ملی و کارت بانکی همگی به وضوح در عکس قابل مشاهده باشند.</li>
                                                </ul>
                                            </div>
                                            <p className="small_hint" > 
                                                ابتدا فایل زیر را دانلود کرده و طبق دستور عمل عکس نهایی را آپلود کنید
                                                <small className='Hint' >
                                                (توجه نمایید اطلاعات درج شده در تصویر باید با اطلاعات ثبت شده در سایت یکسان باشد.)
                                                </small>
                                            </p>
                                            <a target="_blank" className="inline-flex items-center justify-center rounded-md bg-primary py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10 w-full my-5 button_info" href="https://avapay24.co/public/files/AvaPayAUTH_Form.pdf">
                                                دانلود فایل احرازهویت
                                            </a>
                                            <div
                                                id="FileUpload"
                                                className="relative mb-5.5 block w-full cursor-pointer appearance-none rounded border border-dashed border-primary bg-gray py-4 px-4 dark:bg-meta-4 sm:py-7.5"
                                            >
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    className="absolute inset-0 z-50 m-0 h-full w-full cursor-pointer p-0 opacity-0 outline-none"
                                                    onChange={handleFileChange}
                                                />
                                                <div className="flex flex-col items-center justify-center space-y-3">
                                                    <span className="flex h-10 w-10 items-center justify-center rounded-full border border-stroke bg-white dark:border-strokedark dark:bg-boxdark">
                                                        <svg
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 16 16"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M1.99967 9.33337C2.36786 9.33337 2.66634 9.63185 2.66634 10V12.6667C2.66634 12.8435 2.73658 13.0131 2.8616 13.1381C2.98663 13.2631 3.1562 13.3334 3.33301 13.3334H12.6663C12.8431 13.3334 13.0127 13.2631 13.1377 13.1381C13.2628 13.0131 13.333 12.8435 13.333 12.6667V10C13.333 9.63185 13.6315 9.33337 13.9997 9.33337C14.3679 9.33337 14.6663 9.63185 14.6663 10V12.6667C14.6663 13.1971 14.4556 13.7058 14.0806 14.0809C13.7055 14.456 13.1968 14.6667 12.6663 14.6667H3.33301C2.80257 14.6667 2.29387 14.456 1.91879 14.0809C1.54372 13.7058 1.33301 13.1971 1.33301 12.6667V10C1.33301 9.63185 1.63148 9.33337 1.99967 9.33337Z"
                                                                fill="#3C50E0"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M7.5286 1.52864C7.78894 1.26829 8.21106 1.26829 8.4714 1.52864L11.8047 4.86197C12.0651 5.12232 12.0651 5.54443 11.8047 5.80478C11.5444 6.06513 11.1223 6.06513 10.8619 5.80478L8 2.94285L5.13807 5.80478C4.87772 6.06513 4.45561 6.06513 4.19526 5.80478C3.93491 5.54443 3.93491 5.12232 4.19526 4.86197L7.5286 1.52864Z"
                                                                fill="#3C50E0"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M7.99967 1.33337C8.36786 1.33337 8.66634 1.63185 8.66634 2.00004V10C8.66634 10.3682 8.36786 10.6667 7.99967 10.6667C7.63148 10.6667 7.33301 10.3682 7.33301 10V2.00004C7.33301 1.63185 7.63148 1.33337 7.99967 1.33337Z"
                                                                fill="#3C50E0"
                                                            />
                                                        </svg>
                                                    </span>
                                                    <p>
                                                        <span className="text-primary">کلیک کنید</span> یا
                                                        در اینجا رها کنید
                                                    </p>
                                                    <p className="mt-1.5">PNG,JPG</p>
                                                    <p>(max, 2MB)</p>
                                                </div>


                                                {previewUrl && (
                                                    <div className="mb-5 preview">
                                                        <p className='text-center mb-2' ><a href={previewUrl} target="_blank" rel="noopener noreferrer">{fileName}</a></p>
                                                        <img src={previewUrl} alt="Preview" className="mt-3" style={{ maxWidth: '100%', height: 'auto' }} />
                                                    </div>
                                                )}
                                            </div>


                                            {miniLoader ? (
                                                <div className="mini_loader pt-0 mr-4">
                                                    <div className="loader_small"></div>
                                                </div>
                                            )  : (
                                                <div className="flex justify-end gap-4.5">
                                                    <button
                                                        className="flex justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:bg-opacity-90 button w-full mt-6"
                                                        type="submit"
                                                    >
                                                        ثبت فایل احرازهویت
                                                    </button>
                                                </div>
                                            )}

                                            
                                        </form>
                                    )  :  (
                                        <>
                                        <div className="_blank"></div>
                                        </>
                                    )}
                                    </div>
                            </div>
                            <div>
                                <div className="card mt-6 p-7">
                                    <div className="card_title  flex items-center">
                                    <span className="card_heading_text">سایر اطلاعات <small>(الزامی)</small></span>
                                    <span className="card_heading_icon mr-auto">
                                        <svg
                                        width={25}
                                        height={25}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        >
                                        <path
                                            d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                                            stroke="#292D32"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path gb
                                            d="M7.33008 14.4898L9.71008 11.3998C10.0501 10.9598 10.6801 10.8798 11.1201 11.2198L12.9501 12.6598C13.3901 12.9998 14.0201 12.9198 14.3601 12.4898L16.6701 9.50977"
                                            stroke="#2554FF"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        </svg>
                                    </span>
                                    </div>

                                    <form onSubmit={UpdateUser}>

                                        {userData && userData[0] && userData[0]['Identify'] == 1 ? (
                                            <div className="sent_image">احراز هویت شما تایید شده است</div>
                                        )  : (
                                            <>
                                            
                                                <div className="title_lines mb-6">اطلاعات فردی</div>

                                                <div className="mb-7 lg:w-1/2 lg:inline-block lg:pl-3">
                                                    <label
                                                    htmlFor="rate"
                                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                    >
                                                    نام *
                                                    </label>
                                                    <input
                                                    defaultValue={userData && userData[0] && userData[0]["FirstName"]}
                                                    onChange={(e) =>
                                                        (userData[0]["FirstName"] = e.target.value)
                                                    }
                                                    type="text"
                                                    placeholder="Loading..."
                                                    className="flex items-center shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom"
                                                    required
                                                    />
                                                </div>

                                                <div className="mb-7 lg:w-1/2 lg:inline-block lg:pr-3">
                                                    <label
                                                    htmlFor="rate"
                                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                    >
                                                    نام خانوادگی*
                                                    </label>
                                                    <input
                                                    placeholder="Loading..."
                                                    defaultValue={userData && userData[0] && userData[0]["LastName"]}
                                                    onChange={(e) =>
                                                        (userData[0]["LastName"] = e.target.value)
                                                    }
                                                    type="text"
                                                    className="flex items-center shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom"
                                                    required
                                                    />
                                                </div>

                                                <div className="mb-7 lg:w-1/2 lg:inline-block lg:pl-3">
                                                    <label
                                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                    >
                                                    پست الکترونیکی
                                                    <small>(غیرقابل تغییر)</small>
                                                    </label>
                                                    <input
                                                    placeholder="Loading..."
                                                    defaultValue={userData && userData[0] && userData[0]["Email"]}
                                                    type="text"
                                                    className="flex items-center shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom"
                                                    required
                                                    readOnly
                                                    disabled
                                                    />
                                                </div>

                                                <div className="mb-7 lg:w-1/2 lg:inline-block lg:pr-3">
                                                    <label
                                                    htmlFor="rate"
                                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                    >
                                                    شماره همراه *
                                                    </label>
                                                    <input
                                                    placeholder="Loading..."
                                                    defaultValue={userData && userData[0] && userData[0]["Mobile"]}
                                                    onChange={(e) => (userData[0]["Mobile"] = e.target.value)}
                                                    type="text"
                                                    className="flex items-center shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom"
                                                    required
                                                    />
                                                </div>

                                                <div className="mb-7 lg:w-1/2 lg:inline-block lg:pl-3">
                                                    <label
                                                    htmlFor="rate"
                                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                    >
                                                    کد ملی *
                                                    </label>
                                                    <input
                                                    defaultValue={userData && userData[0] && userData[0]["NationalCode"]}
                                                    onChange={(e) =>
                                                        (userData[0]["NationalCode"] = e.target.value)
                                                    }
                                                    type="text"
                                                    className="flex items-center shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom"
                                                    required
                                                    />
                                                </div>

                                                <div className="mb-7 lg:w-1/2 lg:inline-block lg:pr-3">
                                                    <label
                                                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                    >
                                                    تاریخ تولد
                                                    <small>(میلادی)</small>
                                                    *
                                                    </label>

                                                    <input
                                                    dir="rtl"
                                                    defaultValue={userData && userData[0] && userData[0]["BirthDate"]}
                                                    onChange={(e) => (userData[0]["BirthDate"] = e.target.value)}
                                                    type="date"
                                                    className="flex items-center shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom"
                                                    required
                                                    />
                                                </div>


                                                <div className="title_lines mb-6">اطلاعات بانکی</div>

                                                <div className="mb-7 w-full">
                                                    <label
                                                    htmlFor="rate"
                                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                    >
                                                    شماره حساب*
                                                    </label>
                                                    <input
                                                    defaultValue={userData && userData[0] && userData[0]["Hesab"]}
                                                    onChange={(e) => (userData[0]["Hesab"] = e.target.value)}
                                                    type="tel"
                                                    dir="rtl"
                                                    className="flex items-center shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom"
                                                    required
                                                    />
                                                </div>

                                                <div className="mb-7 w-full">
                                                    <label
                                                    htmlFor="rate"
                                                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                                    >
                                                    شماره شبا*
                                                    </label>
                                                    <input
                                                    defaultValue={userData && userData[0] && userData[0]["Sheba"]}
                                                    onChange={(e) => (userData[0]["Sheba"] = e.target.value)}
                                                    type="text"
                                                    className="flex items-center shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom"
                                                    required
                                                    />
                                                </div>


                                                {miniLoader ? (
                                                    <div className="mini_loader pt-0 mr-4">
                                                        <div className="loader_small"></div>
                                                    </div>
                                                )  : (
                                                    <button className="button" type="submit">
                                                        بروزرسانی اطلاعات
                                                    </button>
                                                )}
                                                
                                            </>
                                        )}

                                    </form>
                                </div>
                            </div>
                        </div>
                    </>
                )}



            
            
            </div>
            </div>
        </div>
    </>  
  );
};

export default User;
