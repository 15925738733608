
import React, { useEffect, useState, useRef } from 'react';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import NotifIcon from '../images/notif.svg';

const Navbar = () => {

    const Auth_Cookie = Cookies.get('avaAuth');
    const [notifs , setNotifs] = useState([]);
    const name = Cookies.get('user_FirstName');
    const email = Cookies.get('user_Email');
    const navigate = useNavigate();
    const logOut = async () => {
        Cookies.remove('avaAuth');
        navigate('/login');
    };

    const fetchNotifs = async () => {
        try {
          // Mocking API call to validate cookie, replace with actual API call
          const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/public/api/v1/notifs/notif.php`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Auth_Cookie}`,
              },
            },
          );
  
          if (response.ok) {
            const data = await response.json();
            if (data.status == 200) {
              setNotifs(data.message);
            } else {
              if (data.status == 403) {
                Cookies.remove("avaAuth");
                navigate("/login");
              }
            }
          } else {
            navigate("/login");
          }
        } catch (error) {
          navigate("/login");
        } finally {
          
        }
    };


    useEffect(() => {
        fetchNotifs();
    });

    const DELETE_Notif = async (id,target) => {
        try {

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/public/api/v1/notifs/notif.php?` + `id=${id}`,
            {
                method: "DELETE",
                headers: {
                  'Authorization': `Bearer ${Auth_Cookie}`,
                },
                redirect: "follow",
              },
            );
    
            const data = await response.json();
            if (data.status === 200) {
             //  window.location.reload();
               navigate(`/${target}`);
            } else {          
                alert(data.message)
            }
    
        } catch (error) {
            if (error instanceof Error) {
                console.log(error.message);
            } else {
                console.log('An unexpected error occurred');
            }
        }
    };   


    return (
        <div className="navbar" >
            <div className="user_info flex items-center w-full">
                
                <div className="navbar_info flex items-center">
                    <div className="user_avatar">
                        <i className='bx bxs-user-circle' ></i>
                    </div>
                    <div>
                        <h1>خوش آمدید
                            <b>{name}!</b>
                        </h1>
                        <small>{email}</small>
                    </div>

                    <Link className='mr-auto lg:m-0' to="/identify">
                        <button type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 button nav_btn">
                            احراز هویت
                            <i className="bx bx-left-arrow-alt li_arrow"></i>
                        </button>
                    </Link>
                </div>    

                <div className="flex items-center mr-auto">
                    <div className="notif_logo flex items-center">
                        <span className="notif_counter number">{notifs.length}</span>
                        <img className='lg:ml-5' src={NotifIcon} width={40} height={40} alt="" />

                        <div className="notif_box">
                            <div className="notif_data">
                                <div className="notif_header flex items-center p-4">
                                    <b>اعلانات</b>
                                    <span className="mr-auto number">{notifs.length}</span>
                                </div>
                                <ul>
                                    {notifs.map((notif, index) => (
                                        <li key={index} onClick={() => DELETE_Notif(notif['id'],notif['Category'])} className='notif_row flex items-center'>
                                            <i className='bx bx-chat notif_i'></i>
                                            <div className='pr-3' >
                                                <span className="notif_heading block">پیام جدید</span>
                                                <p className="notif_text">{notif['Title']}</p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>

                    <span className="log_out hidden lg:inline-flex" onClick={logOut}>
                        <svg  fill="#000000" width={40} height={40}  viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>logout</title>
                        <path d="M0 9.875v12.219c0 1.125 0.469 2.125 1.219 2.906 0.75 0.75 1.719 1.156 2.844 1.156h6.125v-2.531h-6.125c-0.844 0-1.5-0.688-1.5-1.531v-12.219c0-0.844 0.656-1.5 1.5-1.5h6.125v-2.563h-6.125c-1.125 0-2.094 0.438-2.844 1.188-0.75 0.781-1.219 1.75-1.219 2.875zM6.719 13.563v4.875c0 0.563 0.5 1.031 1.063 1.031h5.656v3.844c0 0.344 0.188 0.625 0.5 0.781 0.125 0.031 0.25 0.031 0.313 0.031 0.219 0 0.406-0.063 0.563-0.219l7.344-7.344c0.344-0.281 0.313-0.844 0-1.156l-7.344-7.313c-0.438-0.469-1.375-0.188-1.375 0.563v3.875h-5.656c-0.563 0-1.063 0.469-1.063 1.031z"></path>
                        </svg>
                    </span>
                    
                </div>

                
            </div>
        </div>
    );
}

export default Navbar;
