
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
// toast lib ==>
import { Toast } from "primereact/toast";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primereact/resources/primereact.min.css";

import wallet from '../images/TGtRJcjeyT7tCqRXnmEhjk62d9w7E2SpTq.svg'
import trc20 from '../images/tether-usdt-trc20.svg'


// const ManualPayment = ({paymentData}) => {
const ManualPayment = () => {

    const Auth_Cookie = Cookies.get('avaAuth');
    const navigate = useNavigate();
    // uploader =>
    const toast = useRef(null);
    const [miniLoader, setMiniLoader] = useState(false);
    const [usdtBalance, setUsdtBalance] = useState(false);

    const [amount, setAmount] = useState("");
    const [userWallet , setUserWallet ] =  useState("");
    const [setValues , setSetValues] = useState(true);
    const [error , setError] = useState(false);
    const [success , setSuccess] = useState(false);
    const [errorText , setErrorText] = useState("");    
    const [txid , setTxid] = useState("");    

    useEffect(() => {
        if (!Auth_Cookie) {
            navigate('/login');
            return; // Exit early if no cookie is present
        }

        const fetchWallet = async () => {
            try {
              // Mocking API call to validate cookie, replace with actual API call
              const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/public/api/v1/wallets/wallet.php`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${Auth_Cookie}`,
                  },
                },
              );      
              if (response.ok) {
                const data = await response.json();
                console.log(data)
                if (data.status == 200) {
                    setUsdtBalance(data.message[0]['USDT'])
                } else {
                    alert(data.message)
                }
              } else {
                navigate("/login");
              }
            } catch (error) {
              navigate("/login");
            } 
        };
      
        fetchWallet();
    }, []);


 
    function validateTronAddress(address) {
        // بررسی طول آدرس (آدرس‌های TRON 34 کاراکتر دارند)
        if (address.length !== 34) {
          return false;
        }
        // بررسی شروع آدرس با 'T'
        if (address[0] !== 'T') {
          return false;
        }
        // بررسی کاراکترهای معتبر (Base58Check encoding)
        const validChars = /^[1-9A-HJ-NP-Za-km-z]+$/;
        if (!validChars.test(address)) {
          return false;
        }
        return true;
    }

    const withdrawRequest = async(e) =>{
        e.preventDefault();
        if( parseInt(amount) > parseInt(usdtBalance)){
            setError(true);
            setErrorText('مقدار وارد شده از موجودی شما بیشتر است.');
        }else{
            setMiniLoader(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/public/api/v1/payments/USDT.php`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': `Bearer ${Auth_Cookie}`,
                    },
                    body: new URLSearchParams({
                        "Amount": amount,
                        "Wallet": userWallet,
                        "Type": 'Withdraw'
                    }),
                    redirect: "follow"
                });
    
                const data = await response.json();
                console.log(data);
                if (data.status == 200) {
                    if(data.transaction.tron == 'SUCCESS'){
                        setTxid(data.transaction.txID);
                        setSuccess(true);
                    }else{
                        setMiniLoader(false);
                        setError(true);
                        setErrorText(data.transaction.tron);
                    }

                } else {
                    setMiniLoader(false);
                    setError(true);
                    setErrorText(data.transaction.error);
                }
            } catch (error) {
                console.log(error);
                setMiniLoader(false);
                setError(true);
            }
        }

        
    };



    return (
        <>
            <Toast ref={toast} />
            <form className="mb-5" onSubmit={withdrawRequest}>

                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    موجودی تتر من
                    <small><b>USDT</b></small>
                </label>
                <input 
                value={usdtBalance}
                readOnly
                disabled
                placeholder="حداقل 10 تتر" required type="number" min="10" dir="rtl" className="flex items-center shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom number" />



                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white mt-5">
                    مقدار موردنظر
                </label>
                <input 
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder="حداقل 10 تتر" required type="number" min="10" dir="rtl" className="flex items-center shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom number" />


                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white mt-7">
                    کیف پول مقصد
                </label>
                <input 
                value={userWallet}
                onChange={(e) => setUserWallet(e.target.value)}
                placeholder="USDT TRC-20" required type="text" dir="rtl" className="flex items-center shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom number" />

                <div className="hint_box block items-center my-4 mini_hint mb-0">
                    <span className="block" >
                        آواپی هیچ مسئولیتی در قبال اشتباهات کاربران هنگام انتقال رمزارز ندارد. لطفا مراحل را با دقت تکمیل کنید. 
                    </span>
                </div>

                {miniLoader ? (
                    <div className="mini_loader pt-7">
                        <div className="loader_small"></div>
                    </div>
                )  : (
                    <>
                        {error ? (
                            <div className="error_box">{errorText}</div>
                        )  : (
                            <></>
                        )}
                        <button className="button w-full mt-6" type="submit" >برداشت</button>
                    </>
                )}

                {success ? (
                    <div className="success_box block items-center mt-0 mb-4 mini_hint">
                        <span className="block" >تراکنش شما باموفقیت انجام شد. <br /> 
                        کد پیگیری:
                        <b className="number">{txid}</b>
                        </span>
                    </div>
                )  : (
                    <></>
                )}
            </form>

        </>
        
    );
}

export default ManualPayment;
