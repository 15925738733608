

function timeAgo(timestamp) {
    const now = new Date();
    const timeDifference = now - new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
        return days + ' روز پیش';
    } else if (hours > 0) {
        return hours + ' ساعت پیش';
    } else if (minutes > 0) {
        return minutes + ' دقیقه پیش';
    } else {
        return seconds + ' ثانیه پیش';
    }
}

export { timeAgo };
