import React from 'react';
import AppRouter from './router';
import './fonts/fontiran.css';
import './tailwind.css';
import './style.css';
import './vedors/boxicons-2.1.4/css/boxicons.min.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <AppRouter /> 
      </header>
    </div>
  );
}

export default App;
