import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import Sidebar from "../components/sidebar";
import Navbar from "../components/navbar";
import StatusDiv from '../utils/status';
import Type from '../utils/type';
import { timeAgo } from '../utils/timeAgo';
import ManualPayment from '../components/manualpayment';
import Withdraw from '../components/withdraw';
import USDT from '../components/usdt';
import USDTwithdraw from '../components/usdt_withdraw';

// toast lib ==>
import { Toast } from 'primereact/toast';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primereact/resources/primereact.min.css';

// Dialog ==>
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import rial from '../images/IRR.svg';


const Payments = () => {
    const Auth_Cookie = Cookies.get('avaAuth');
    const user_role = Cookies.get("theme");
    const [isLoading, setIsLoading] = useState(true);
    const [isValidCookie, setIsValidCookie] = useState(false);
    const toast = useRef(null);
    const [payments, setPayments] = useState([]);
    const [paymentDetails, setPaymentDetails] = useState([]);
    const [showPayment, setShowPayment] = useState(false);
    const [withdraw, setShowWithdraw] = useState(false);
    const [identify, setIdentify] = useState(null);
    const [Role, setRole] = useState("user");
    const [InnerLoader, setInnerLoader] = useState(false);
    const [ContentLoading, setContentLoading] = useState(true);
    const [miniLoader, setMiniLoader] = useState(false);
    const [depositWays , setDepositWay] = useState(false);
    const [usdtGateway , setUsdtGateway] = useState(false);
    const [withdrawWay , setWithdrawWay] = useState(false);
    const [usdtGatewayWithDraw , setUsdtGatewayWithDraw] = useState(false);

    // add order ==>
    const navigate = useNavigate();

    const decrypt = (ciphertext) => {
        const bytes = CryptoJS.AES.decrypt(ciphertext, process.env.REACT_APP_SECRET_KEY);
        return bytes.toString(CryptoJS.enc.Utf8);
    };

    const fetchPayments = async () => {
        try {
          // Mocking API call to validate cookie, replace with actual API call
          const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/public/api/v1/payments/payment.php`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Auth_Cookie}`,
              },
            },
          );
  
          if (response.ok) {
            const data = await response.json();
            if (data.status == 200) {
              setPayments(data.message);
              setIsValidCookie(true);
              setIsLoading(false);
              setContentLoading(false);
            } else {
              if (data.status == 403) {
                Cookies.remove("avaAuth");
                navigate("/login");
              } else {
                toast.current.show({
                  severity: "error",
                  summary: "Error",
                  detail: data.message,
                  life: 5000,
                });
              }
            }
          } else {
            navigate("/login");
          }
        } catch (error) {
          navigate("/login");
        } finally {
          setIsLoading(false);
        }
    };
    
    useEffect(() => {
    
        if (!Auth_Cookie) {
          navigate("/login");
          return; // Exit early if no cookie is present
        }
    
        if (decrypt(user_role) == 'user') {
            setRole('user')
        } else if(decrypt(user_role) == 'admin'){
            setRole('admin')
        }else{
            setRole('user')
        }
    }, [Role, setRole]);

    useEffect(() => {
        if (!Auth_Cookie) {
            navigate('/login');
            return; // Exit early if no cookie is present
        }

        const fetchUser = async () => {
            try {
              // Mocking API call to validate cookie, replace with actual API call
              const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/public/api/v1/users/user.php`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${Auth_Cookie}`,
                  },
                },
              );      
              if (response.ok) {
                const data = await response.json();
                if (data.status == 200) {
                  setIdentify(data.message[0].Identify)
                  setIsValidCookie(true);
                  setIsLoading(false);
                } else {
                    alert(data.message)
                }
              } else {
                navigate("/login");
              }
            } catch (error) {
              navigate("/login");
            } 
        };
      
        fetchUser();
    }, []);

    useEffect(() => {
        if (!Auth_Cookie) {
            navigate('/login');
            return; // Exit early if no cookie is present
        }     
        fetchPayments();
    }, [navigate]);
    

    const handlePaymentClick = (payment) => {
        setDepositWay(true)
    };

    const handleWithdrawClick = (payment) => {
      //  setShowPayment(false);
      //  setShowWithdraw(true);
        setWithdrawWay(true)
    }

    const openRialWithdraw = () => {
        setWithdrawWay(false)
        setShowPayment(false);
        setShowWithdraw(true);
    }

    const openRialCard = () => {
        setShowPayment(true);
        setShowWithdraw(false);
        setDepositWay(false);
    }

    const openUSDT = () => {
        setUsdtGateway(true);
        setShowWithdraw(false);
        setDepositWay(false);  
    };

    const openUSDTwithdraw = () => {
        setWithdrawWay(false);
        setUsdtGatewayWithDraw(true);
        setShowWithdraw(false);
    };

 

    const removePayment = (payment) => {
        console.log('error')
    };    

    const addDeposit = (payment) => {
        console.log('error')
    };    

    const acceptPayment = async (payment,status) => {
        try {
            setInnerLoader(true);
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/public/api/v1/payments/payment.php?` +
              `id=${payment['id']}&` +
              `Status=${parseInt(status)}`
            ,
            {
                method: "PUT",
                headers: {
                  'Authorization': `Bearer ${Auth_Cookie}`,
                },
                redirect: "follow",
              },
            );
    
            const data = await response.json();
            if (data.status === 200) {
               window.location.reload();
            } else {          
                alert(data.message)
                setInnerLoader(false);
            }
    
        } catch (error) {
            if (error instanceof Error) {
                console.log(error.message);
            } else {
                console.log('An unexpected error occurred');
            }
        }
    };

    const [visibleDeposit, setVisibleDeposit] = useState(false);
    const footerContentDepositWays = (
        <>
        {miniLoader ? (
            <div className="mini_loader pt-0 mr-4">
                <div className="loader_small"></div>
            </div>
        )  : (
            
            <div className="grid grid-cols-2 gap-5" >
                <Button label="تایید" type="submit" icon="pi pi-check" onClick={addDeposit} autoFocus />
                <Button label="انصراف" icon="pi pi-times" onClick={() => setVisibleDeposit(false)} className="p-button-text" />
            </div>
            
        )}
        </>
    );
    
    return  (
        <>
        {InnerLoader != "" ? (
            <div className="loading_wrapper">
                <span className="loader"></span>
            </div>
        )  : (
            <></>
        )} 
        <Toast ref={toast} />
        <div className="lg:flex" >
            <Sidebar/>
            <div className="mainbar" >
                <Navbar/>
                <div className="main">
                    {showPayment ? (
                        // <ManualPayment paymentData={paymentDetails} />
                        <ManualPayment />
                    )  : (
                        <></>
                    )}

                    {withdraw ? (
                        <Withdraw />
                    )  : (
                        <></>
                    )}
                    <div className="single_page_title">
                        
                        <h1>واریز یا برداشت</h1>
                        <h2>Deposit / Withdraw</h2>

                        <div className="mr-auto"><i className="bx bx-wallet-alt li_icon"></i></div>
                    </div>

                    
                    <div >

                        {identify === 1 ? (
                            <>
                                <button type='button' className='button mt-5 ml-4' onClick={() => handlePaymentClick()} >
                                    <i className="pi pi-plus-circle ml-2" style={{ fontSize: '1rem' }}></i>
                                    ایجاد واریزی
                                </button>
                                <button type='button' className='button mt-5 widthraw_button' onClick={() => handleWithdrawClick()} >
                                    <i className="pi pi-arrow-circle-down ml-2" style={{ fontSize: '1rem' }}></i>
                                    درخواست برداشت
                                </button>

                                <Dialog header="راه های واریز" visible={depositWays} className="dialoge_box window_dialoge" onHide={() => {if (!depositWays) return; setDepositWay(false); }} >

                                    <ul className="GateWays pt-1 pb-6">
                                        <p className="gateway_hint">
                                            لطفا یکی از راه های واریز وجه را انتخاب کنید تا به درگاه مربوطه متصل شوید
                                        </p>
                                        <li className='flex items-center usdt_gateway' onClick={() => openUSDT()} >
                                            <svg className='ml-3' fill="#26A17B" width={30} height={30} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm1.922-18.207v-2.366h5.414V7.819H8.595v3.608h5.414v2.365c-4.4.202-7.709 1.074-7.709 2.118 0 1.044 3.309 1.915 7.709 2.118v7.582h3.913v-7.584c4.393-.202 7.694-1.073 7.694-2.116 0-1.043-3.301-1.914-7.694-2.117zm0 3.59v-.002c-.11.008-.677.042-1.942.042-1.01 0-1.721-.03-1.971-.042v.003c-3.888-.171-6.79-.848-6.79-1.658 0-.809 2.902-1.486 6.79-1.66v2.644c.254.018.982.061 1.988.061 1.207 0 1.812-.05 1.925-.06v-2.643c3.88.173 6.775.85 6.775 1.658 0 .81-2.895 1.485-6.775 1.657z"></path></svg>
                                            <div className="gate_info">
                                                <span className="gateway_title flex">درگاه تتر - <b className='number mr-2' >TRC20</b> </span>
                                                <small>
                                                    واریز حداقل 
                                                    <b className='number mr-2' >10 USDT</b>
                                                </small>
                                            </div>
                                            <i className="bx bx-left-arrow-alt mr-auto"></i>
                                        </li>
                                        <li className='flex items-center rial_gateway' onClick={() => openRialCard()}   >
                                            <img width={30} height={30} className='ml-3' src={rial} alt="iranian rial" />
                                            <div className="gate_info">
                                                <span className="gateway_title flex">انتقال وجه آفلاین</span>
                                                <small>
                                                    انتقال به شماره حساب
                                                </small>
                                            </div>
                                            <i className="bx bx-left-arrow-alt mr-auto"></i>
                                        </li>

   
                                    </ul>

                                </Dialog>

                                <Dialog header="درگاه واریز تتر" visible={usdtGateway} className="dialoge_box" onHide={() => {if (!usdtGateway) return; setUsdtGateway(false); }} ><USDT /></Dialog>


                                <Dialog header="راه های برداشت" visible={withdrawWay} className="dialoge_box window_dialoge" onHide={() => {if (!withdrawWay) return; setWithdrawWay(false); }} >

                                    <ul className="GateWays pt-1 pb-6">
                                        <p className="gateway_hint">
                                            لطفا یکی از راه های برداشت وجه را انتخاب کنید
                                        </p>
                                        <li className='flex items-center usdt_gateway' onClick={() => openUSDTwithdraw()} >
                                            <svg className='ml-3' fill="#26A17B" width={30} height={30} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm1.922-18.207v-2.366h5.414V7.819H8.595v3.608h5.414v2.365c-4.4.202-7.709 1.074-7.709 2.118 0 1.044 3.309 1.915 7.709 2.118v7.582h3.913v-7.584c4.393-.202 7.694-1.073 7.694-2.116 0-1.043-3.301-1.914-7.694-2.117zm0 3.59v-.002c-.11.008-.677.042-1.942.042-1.01 0-1.721-.03-1.971-.042v.003c-3.888-.171-6.79-.848-6.79-1.658 0-.809 2.902-1.486 6.79-1.66v2.644c.254.018.982.061 1.988.061 1.207 0 1.812-.05 1.925-.06v-2.643c3.88.173 6.775.85 6.775 1.658 0 .81-2.895 1.485-6.775 1.657z"></path></svg>
                                            <div className="gate_info">
                                                <span className="gateway_title flex">درگاه تتر - <b className='number mr-2' >TRC20</b> </span>
                                                <small>
                                                    واریز آنی - حداقل 
                                                    <b className='number mr-2' >10 USDT</b>
                                                </small>
                                            </div>
                                            <i className="bx bx-left-arrow-alt mr-auto"></i>
                                        </li>
                                        <li className='flex items-center rial_gateway' onClick={() => openRialWithdraw()}   >
                                            <img width={30} height={30} className='ml-3' src={rial} alt="iranian rial" />
                                            <div className="gate_info">
                                                <span className="gateway_title flex">انتقال وجه آفلاین</span>
                                                <small>
                                                    انتقال به شماره حساب ثبت شده
                                                </small>
                                            </div>
                                            <i className="bx bx-left-arrow-alt mr-auto"></i>
                                        </li>

                                    </ul>

                                </Dialog>

                                <Dialog header="درگاه برداشت تتر" visible={usdtGatewayWithDraw} className="dialoge_box" onHide={() => {if (!usdtGatewayWithDraw) return; setUsdtGatewayWithDraw(false); }} ><USDTwithdraw /></Dialog>
                                
                            </>
                        ) : identify === 0 ? (
                            <div className="auth_message my-4 big_error">
                                <h4>برای عملیات برداشت یا واریز نیاز به احرازهویت دارید</h4>
                            </div>
                        ) : (
                            <div className="mini_loader">
                                <div className="loader_small"></div>
                            </div>
                        )}

                        <div className="hint_box flex items-center mt-4 mb-0">
                            <i className="pi pi-bell" style={{ fontSize: '1.5rem' }}></i>
                            <div  className="pr-5" >
                                <b className="mb-2 block" >در اینجا می توانید واریز یا برداشت از کیف پول تومانی خود را انجام دهید</b>
                                <span>در صورتی که درخواست خود را ثبت کرده اید می توانید از طریق همین صفحه وضعیت درخواست خود را پیگیری کنید.</span>
                            </div>
                        </div>

                            

                            {ContentLoading == true ? (
                                <div className="lg:grid lg:grid-cols-1 gap-5 mt-8 card p-0">
                                    <span className="content_loader"></span>
                                </div>
                            )  : (
                                <div className="card p-5 mt-6 w-full">
                                    <div className="table_scroll">
                                        {payments.length > 0 ? (
                                        <>
                                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                                    <tr>
                                                        <th scope="col" className="px-6 py-3">
                                                            مقدار 
                                                        </th>
                                                        <th scope="col" className="px-6 py-3">
                                                            وضعیت
                                                        </th>
                                                        <th scope="col" className="px-6 py-3">
                                                            نوع
                                                        </th>
                                                        <th scope="col" className="px-6 py-3">
                                                            تاریخ ایجاد 
                                                        </th>
                                                        <th scope="col" className="px-6 py-3">
                                                            کدپیگیری
                                                        </th>
                                                        <th scope="col" className="px-6 py-3">
                                                            توضیحات
                                                        </th>
                                                        {Role != "admin" ? (
                                                            <></>
                                                        )  : (
                                                            <>
                                                                <th scope="col" className="px-6 py-3">
                                                                ایمیل تراکنش
                                                                </th>
                                                                <th scope="col" className="px-6 py-3">
                                                                عملیات
                                                                </th>
                                                            </>
                                                            
                                                        )}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {payments.map((payment, index) => (
                                                    <tr
                                                        key={index}
                                                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 order_success"
                                                    >
                                                        <td className="px-6 py-5 number">
                                                        {parseInt(payment["Amount"]).toLocaleString()}
                                                        <b className='unit' >{payment['Currency']}</b>
                                                        </td>
                                                        <td className="px-6 py-3">
                                                        <StatusDiv statusCode={payment["Status"]} />
                                                        </td>
                                                        <td className="px-6 py-3">
                                                        <Type typeString={payment["Type"]} />
                                                        </td>
                                                        <td className="px-6 py-3">
                                                        {timeAgo(payment["Date"])}
                                                        </td>
                                                        <td className="px-6 py-3">
                                                        <input readOnly disabled className='trackingcode' value={payment['TrackingCode']} type="text" />
                                                        </td>
                                                        <td className="px-6 py-3">
                                                        {payment['RejectReason']}
                                                        </td>
                                                        {Role != "admin" ? (
                                                            <></>
                                                        )  : (
                                                            <>
                                                                <td className="px-6 py-3">
                                                                    {payment['Email']}
                                                                </td>
                                                                <td className="px-6 py-3 table_buttons flex items-center gap-4">
                                                                    <a className="button mini_button inline-flex" target="_bank" href={ process.env.REACT_APP_BASE_URL + '/public/api/v1/file/uploads/' + payment['Image']}>
                                                                    تصویر
                                                                    </a>

                                                                    {payment['Status'] == 102 ? (
                                                                        <>
                                                                            <button className='button mini_button' onClick={() => acceptPayment(payment,401)} >
                                                                                رد
                                                                            </button>
                                                                            <button className='button mini_button' onClick={() => acceptPayment(payment,200)} >
                                                                                تایید
                                                                            </button> 
                                                                        </>
                                                                                                                                                                                                                
                                                                    )  : (
                                                                        <></>
                                                                    )}
                                                                </td>
                                                                
                                                            </>
                                                        )}
                                                    </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </>

                                        )  : (
                                            <div className="hint_box flex items-center">
                                                <i className="pi pi-exclamation-triangle" style={{ fontSize: '1.5rem' }}></i>
                                                <div  className="pr-5" >
                                                    <b className="block" >
                                                    شما هیچ پرداختی انجام نداده اید.
                                                    </b>
                                                    
                                                </div>
                                            </div>
                                        )}
                                        
                                    </div>
                                </div>
                            )}
                                
                            
                    </div>

                </div>
            </div>
        </div>

    </>
    )
};

export default Payments;
