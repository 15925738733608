
import React, {  useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
// toast lib ==>
import { Toast } from "primereact/toast";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primereact/resources/primereact.min.css";

import wallet from '../images/TGtRJcjeyT7tCqRXnmEhjk62d9w7E2SpTq.svg'
import trc20 from '../images/tether-usdt-trc20.svg'


// const ManualPayment = ({paymentData}) => {
const ManualPayment = () => {

    const Auth_Cookie = Cookies.get('avaAuth');
    const navigate = useNavigate();
    // uploader =>
    const [previewUrl, setPreviewUrl] = useState(null);
    const [fileName, setFileName] = useState("");
    const [image, setImage] = useState(null);
    const [InnerLoader, setInnerLoader] = useState(false);
    const [trackingCode, setTrackingCode] = useState("");
    const [gateway, setGateway] = useState("Online");
    const toast = useRef(null);
    const [miniLoader, setMiniLoader] = useState(false);

    const [amount, setAmount] = useState("");
    const [userWallet , setUserWallet ] =  useState("");
    const [setValues , setSetValues] = useState(true);
    const [error , setError] = useState(false);
    const [success , setSuccess] = useState(false);
    const [errorText , setErrorText] = useState("");    

    function validateTronAddress(address) {
        // بررسی طول آدرس (آدرس‌های TRON 34 کاراکتر دارند)
        if (address.length !== 34) {
          return false;
        }
        // بررسی شروع آدرس با 'T'
        if (address[0] !== 'T') {
          return false;
        }
        // بررسی کاراکترهای معتبر (Base58Check encoding)
        const validChars = /^[1-9A-HJ-NP-Za-km-z]+$/;
        if (!validChars.test(address)) {
          return false;
        }
        return true;
    }
 
    const PaymentLog = async (txID) => {

        // Create deposit payment log USDT ==>
        try {
            const formData = new FormData();            
            formData.append('Image', 'none.png');
            formData.append('Amount', parseInt(amount));
            formData.append('Type', 'Deposit');
            formData.append('TrackingCode', txID);
            formData.append('Gateway', gateway);
            formData.append('Currency', 'USDT');
            formData.append('status', 200);

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${Auth_Cookie}`,
                },
                body: formData,
                redirect: 'follow'
            };

            const response = await fetch( process.env.REACT_APP_BASE_URL + '/public/api/v1/payments/payment.php', requestOptions);
            const data = await response.json();
            if (data.status  == 200) {
                navigate('/');
            } else {
                alert(data.message);
                console.log(txID)
                console.log(gateway)
                console.log(parseInt(amount))
            }
        } catch (error) {
            console.log(error);
            setInnerLoader(false);
        }

    };


    const switchToUSDTGateway = (e) =>{
        e.preventDefault();
        setMiniLoader(true);

        if (validateTronAddress(userWallet) == false){
            setError(true);
            setErrorText('آدرس کیف پول وارد شده معتبر نمی باشد.')
            setMiniLoader(false);
        } else{
            setError(false);
            setMiniLoader(false);
            setSetValues(false);
        }
    };

    const ChargeBalance = async (txID) => {
        setMiniLoader(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/public/api/v1/payments/USDT.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${Auth_Cookie}`,
                },
                body: new URLSearchParams({
                    "txID": txID,
                    "Amount": amount,
                    "Wallet": userWallet,
                    "Type": 'Deposit'
                }),
                redirect: "follow"
            });

            const data = await response.json();
            if (data.status == 200) {
                PaymentLog(txID);
            } else {
                console.log(data)
                setMiniLoader(false);
                setError(true);
            }
        } catch (error) {
            console.log(error);
            setMiniLoader(false);
            setError(true);
        }
    };

    const CreatePayment = async () => {
        setMiniLoader(true);
        try {
            setInnerLoader(true);
            const response = await fetch(`https://avapayhost.com/transfer/istransfer?` +
              `User_wallet=${userWallet}&` +
              `Amount=${parseInt(amount)}`
            ,
            {
                method: "GET",
                headers: {
                  'Authorization': `Bearer ${Auth_Cookie}`,
                },
                redirect: "follow",
              },
            );
    
            const data = await response.json();
            if(data.result.success == true){
                setMiniLoader(true);
               // setSuccess(true);
                ChargeBalance(data.result['transaction']['transaction_id']);
                setTrackingCode(data.result['transaction']['transaction_id'])
            }else{
                setMiniLoader(false);
                setError(true);
                console.log('NotFound');
                console.log(data)
            }
    
        } catch (error) {
            if (error instanceof Error) {
                console.log(process.env.NODE_JS_USDT);
                setMiniLoader(false);

            } else {
                console.log('An unexpected error occurred');
                setMiniLoader(false);

            }
        }
    };

    

    return (
        <>
            <Toast ref={toast} />

            {setValues ? (
                <form className="mb-5" onSubmit={switchToUSDTGateway}>
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        مقدار تتر
                    </label>
                    <input 
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    placeholder="حداقل 10 تتر" required type="number" min="10" dir="rtl" className="flex items-center shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom number" />
                    

                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white mt-7">
                        آدرس کیف پول واریز کننده
                    </label>
                    <input 
                    value={userWallet}
                    onChange={(e) => setUserWallet(e.target.value)}
                    placeholder="USDT TRC-20" required type="text" dir="rtl" className="flex items-center shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom number" />
                    
                    <div className="hint_box block items-center my-4 mini_hint mb-0">
                        <span className="block" >
                            آواپی هیچ مسئولیتی در قبال اشتباهات کاربران هنگام انتقال رمزارز ندارد. لطفا مراحل را با دقت تکمیل کنید. 
                        </span>
                    </div>

                    {miniLoader ? (
                        <div className="mini_loader pt-7">
                            <div className="loader_small"></div>
                        </div>
                    )  : (
                        <>
                            {error ? (
                                <div className="error_box">{errorText}</div>
                            )  : (
                                <></>
                            )}
                            <button className="button w-full mt-6" type="submit" >ادامه</button>
                        </>
                    )}
                </form>
            )  : (

                <>
                    <div className="hint_box block items-center mt-0 mb-4 mini_hint">
                        <span className="block" >مقدار وارد شده باید با مقدار واریزی برابر باشد در غیراینصورت تراکنش شما تایید نخواهد شد</span>
                    </div>
                    <div className="flex items-center justify-center" dir="ltr" >
                        <img className="mr-2" src={trc20} width={40} height={40} alt="USDT - TRC20" />
                        <span className="number" > <b className="amount_no" >{amount}</b> USDT <small className="label ml-2" >TRON</small></span>
                    </div>
                    <img src={wallet} className="wallet_qr" alt="Wallet Address QR" />

                    <div className="text-center">
                        <div className="wallet_address number">
                        <p>TGtRJcjeyT7tCqRXnmEhjk62d9w7E2SpTq</p>
                        </div>
                        <small className="smal_hint">چند دقیقه پس از واریز بر روی دکمه زیر کلیک کنید</small>
                    </div>

                    {success ? (
                        <div className="success_box block items-center mt-4 mb-0 mini_hint">
                            <span className="block" >تراکنش شما باموفقیت تایید شد. <br /> <b>لطفا منتظر بمانید...</b>
                            </span>
                        </div>
                    )  : (
                        <></>
                    )}

                    {miniLoader ? (
                        <div className="mini_loader py-7">
                            <div className="loader_small"></div>
                        </div>
                    )  : (
                        <>
                            {error ? (
                                <div className="error_box block items-center mb-0 mt-4 mini_hint">
                                    <span className="block" >تراکنش شما هنوز تایید نشده است. <br /> لطفا چند دقیقه دیگر تلاش کنید
                                    <b>(در همین پنجره بمانید)</b>
                                    </span>
                                </div>
                            )  : (
                                <></>
                            )}
                            <button className="button w-full mt-4 mb-3 lg:mb-3" type="submit" onClick={CreatePayment} >واریز کردم</button>
                        </>
                        
                    )}


                </>
                
            )}
            
        </>
       
        
    );
}

export default ManualPayment;
