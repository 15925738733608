import React from 'react';

const GetType = ({ typeString }) => {

  const switchType = (type) => {
    switch (type) {
      case 'Deposit':
        return 'واریز';
      case 'BalanceToWallet':
         return 'خرید';
      case 'Withdraw':
        return 'برداشت';
      case 'WalletToBalance':
        return 'فروش';        
      default:
        return 'وضعیت نامشخص';
    }
  };

  return (
    <div className={typeString}>
      {switchType(typeString)}
    </div>
  );
};

export default GetType;
