
import React, {  useState, useRef } from "react";
import Cookies from 'js-cookie';

// bank logos =>
import ayande from '../images/bank_ayande.svg';
import keshavarzi from '../images/bank_keshavarzi.svg';
import mellat from '../images/bank_mellat.svg';
import parsian from '../images/bank_parsian.svg';
import saman from '../images/bank_saman.svg';
import shahr from '../images/bank_shahr.svg';

// toast lib ==>
import { Toast } from "primereact/toast";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primereact/resources/primereact.min.css";


// const ManualPayment = ({paymentData}) => {
const ManualPayment = () => {

    const Auth_Cookie = Cookies.get('avaAuth');
    // uploader =>
    const [previewUrl, setPreviewUrl] = useState(null);
    const [fileName, setFileName] = useState("");
    const [image, setImage] = useState(null);
    const [InnerLoader, setInnerLoader] = useState(false);
    const [trackingCode, setTrackingCode] = useState("");
    const [amount, setAmount] = useState("");
    const [gateway, setGateway] = useState("Manual");
    const toast = useRef(null);
    const [miniLoader, setMiniLoader] = useState(false);
    const inputValue = amount == "" ? '0' : parseInt(amount).toLocaleString();
    console.log(amount)

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImage(file);
            setPreviewUrl(URL.createObjectURL(file));
            setFileName(file.name);
        } else {
            setImage(null);
            setPreviewUrl(null);
            setFileName("");
        }
    };

    const isWithinTwentyMinutes = (timestamp) => {
        const now = Date.now();
        const timestampMs = parseInt(timestamp) * 1000;
        const differenceInMilliseconds = now - timestampMs;
        const differenceInMinutes = differenceInMilliseconds / 1000 / 60;
        return differenceInMinutes <= 20;

    };


    const CreatePayment = async (e) => {

        if(trackingCode != null){
            e.preventDefault();
            setMiniLoader(true);
            try {
              const formData = new FormData();
              
              formData.append('Image', image);
              formData.append('Amount', parseInt(amount));
              formData.append('Type', 'Deposit');
              formData.append('TrackingCode', trackingCode);
              formData.append('Gateway', gateway);
              formData.append('Currency', 'TOMAN');

              const requestOptions = {
                  method: 'POST',
                  headers: {
                      'Authorization': `Bearer ${Auth_Cookie}`,
                  },
                  body: formData,
                  redirect: 'follow'
              };
    
              const response = await fetch( process.env.REACT_APP_BASE_URL + '/public/api/v1/payments/payment.php', requestOptions);
              const data = await response.json();
              if (data.status  == 200) {
                    toast.current.show({ severity: 'success', summary: 'موفق', detail: 'پرداخت شما با موفقیت ثبت شد' , life: 7000 });
                    window.location.reload();
                } else {
                    setMiniLoader(false);
                    toast.current.show({ severity: 'error', summary: 'خطا', detail: data.message , life: 7000 });
                }
            } catch (error) {
                console.log(error);
                setInnerLoader(false);
            }
        }else{
            toast.current.show({ severity: 'error', summary: 'خطا', detail: 'کد پیگیری را وارد کنید' , life: 5000 });
        }

    };

    return (
        <>
            <Toast ref={toast} />
            <div className='card p-7 mt-6 w-full mb-5' >
                <div className="hint_box flex items-center mb-7">
                    <i className="pi pi-receipt" style={{ fontSize: '1.5rem' }}></i>
                    <div  className="pr-5" >
                        <b className="mb-2 block" >پرداخت با شماره حساب - شبا</b>
                        <span className='flex mb-2' >پس از پرداخت , اطلاعات پرداخت و فیش واریزی را آپلود نموده و دکمه ثبت را بزنید</span>
                        <span>در صورتی که حساب واریز کننده با مشخصات ثبت شده در پنل کاربری مغایرت داشته باشد , پرداخت شما رد خواهد شد.</span>
                    </div>
                </div>            
                {/* <div className="sent_image mb-5">پرداخت حساب به حساب</div> */}
                <div className="lg:flex gap-5">
                    <div className="lg:w-2/5 border_left">                            
                                <form onSubmit={CreatePayment}>

                                    <div className="mb-5 w-full">
                                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            مقدار
                                            <small>TOMAN</small>
                                        </label>
                                        <input
                                        dir="rtl"
                                        value={amount}
                                        onChange={(e) => setAmount(e.target.value)}
                                        type="tel" className="number shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom" required />
                                    </div>
                                

                                    <ul className="final_payment flex items-center">
                                        <li>
                                        قابل پرداخت:
                                        </li>

                                        <li className='mr-auto' > 
                                                    <b>تومان</b>
                                                    <span className='number' >{inputValue}</span>

                                        </li>
                                    </ul>

                                    <hr className='my-4'  />

                                    <div className="mb-7 w-full">
                                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">کد پیگیری: <small>(الزامی)</small></label>
                                        <input
                                        value={trackingCode}
                                        onChange={(e) => setTrackingCode(e.target.value)}
                                        type="text" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom" placeholder="وارد کنید" required />
                                    </div>

                                    <div id="FileUpload"
                                        className="relative mb-5.5 block w-full cursor-pointer appearance-none rounded border border-dashed border-primary bg-gray py-4 px-4 dark:bg-meta-4 sm:py-7.5"
                                    >
                                        
                                        <input type="file" accept="image/*" className="absolute inset-0 z-50 m-0 h-full w-full cursor-pointer p-0 opacity-0 outline-none"
                                            onChange={handleFileChange}
                                        />         

                                        {previewUrl == null ? (
                                            
                                            <div className="flex flex-col items-center justify-center space-y-3">
                                            <span className="flex h-10 w-10 items-center justify-center rounded-full border border-stroke bg-white dark:border-strokedark dark:bg-boxdark">
                                                <svg
                                                    width="16"
                                                    height="16"
                                                    viewBox="0 0 16 16"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M1.99967 9.33337C2.36786 9.33337 2.66634 9.63185 2.66634 10V12.6667C2.66634 12.8435 2.73658 13.0131 2.8616 13.1381C2.98663 13.2631 3.1562 13.3334 3.33301 13.3334H12.6663C12.8431 13.3334 13.0127 13.2631 13.1377 13.1381C13.2628 13.0131 13.333 12.8435 13.333 12.6667V10C13.333 9.63185 13.6315 9.33337 13.9997 9.33337C14.3679 9.33337 14.6663 9.63185 14.6663 10V12.6667C14.6663 13.1971 14.4556 13.7058 14.0806 14.0809C13.7055 14.456 13.1968 14.6667 12.6663 14.6667H3.33301C2.80257 14.6667 2.29387 14.456 1.91879 14.0809C1.54372 13.7058 1.33301 13.1971 1.33301 12.6667V10C1.33301 9.63185 1.63148 9.33337 1.99967 9.33337Z"
                                                        fill="#3C50E0"
                                                    />
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M7.5286 1.52864C7.78894 1.26829 8.21106 1.26829 8.4714 1.52864L11.8047 4.86197C12.0651 5.12232 12.0651 5.54443 11.8047 5.80478C11.5444 6.06513 11.1223 6.06513 10.8619 5.80478L8 2.94285L5.13807 5.80478C4.87772 6.06513 4.45561 6.06513 4.19526 5.80478C3.93491 5.54443 3.93491 5.12232 4.19526 4.86197L7.5286 1.52864Z"
                                                        fill="#3C50E0"
                                                    />
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M7.99967 1.33337C8.36786 1.33337 8.66634 1.63185 8.66634 2.00004V10C8.66634 10.3682 8.36786 10.6667 7.99967 10.6667C7.63148 10.6667 7.33301 10.3682 7.33301 10V2.00004C7.33301 1.63185 7.63148 1.33337 7.99967 1.33337Z"
                                                        fill="#3C50E0"
                                                    />
                                                </svg>
                                            </span>
                                                <p>
                                                    <span className="text-primary">
                                                        تصویر واضح فیش واریزی 
                                                        <small>(الزامی)</small>
                                                    </span>
                                                </p>
                                                <p className="mt-1.5">PNG,JPG</p>
                                                <p>(max, 2MB)</p>
                                            </div>

                                        )  : (
                                            <div className="mb-5 preview">
                                                <p className='text-center mb-2' ><a href={previewUrl} target="_blank" rel="noopener noreferrer">{fileName}</a></p>
                                            </div>
                                        )}                                         

                                    </div>

                                    {miniLoader ? (
                                        <div className="mini_loader pt-8 mr-4">
                                            <div className="loader_small"></div>
                                        </div>
                                    )  : (
                                        <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 w-full mt-5 button">ثبت پرداخت</button>
                                    )}
   
                                </form>
                    </div>
                    <div className="lg:w-3/5 mb-6 mt-9 lg:mt-0">
                            <h2 className='mb-4' >نوآوران راهبرد سپهر پایا</h2>
                        <div className="table_scroll table_details">
                                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 min700">
                                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                            <tr>
                                                <th scope="col" className="px-6 py-3">
                                                    نام بانک
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    شماره حساب
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    شماره شبا
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 order_success"
                                            >
                                                <th
                                                scope="row"
                                                className="flex items-center px-6 py-3 text-gray-900 whitespace-nowrap dark:text-white"
                                                >
                                                    <div className="bank_info" >
                                                        <div className="font-normal text-gray-500 mt-1">
                                                            <img src={saman} alt="بانک سامان" />
                                                            بانک سامان
                                                        </div>
                                                    </div>
                                                </th>
                                                <td className="px-6 py-3 number" dir="ltr" >
                                                    838-810-2737677-1
                                                </td>
                                                <td className="px-6 py-3 number" dir="ltr" >
                                                IR13-0560-0838-8100-2737-6770-01
                                                </td>

                                            </tr>

                                            <tr
                                                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 order_success"
                                            >
                                                <th
                                                scope="row"
                                                className="flex items-center px-6 py-3 text-gray-900 whitespace-nowrap dark:text-white"
                                                >
                                                    <div className="bank_info" >
                                                        <div className="font-normal text-gray-500 mt-1">
                                                            <img src={parsian} alt="بانک پارسیان" />
                                                            بانک پارسیان
                                                        </div>
                                                    </div>
                                                </th>
                                                <td className="px-6 py-3 number">
                                                    47001350503601
                                                </td>
                                                <td className="px-6 py-3 number">
                                                IR13-0560-0838-8100-2737-6770-01
                                                </td>
                                            </tr>

                                            <tr
                                                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 order_success"
                                            >
                                                <th
                                                scope="row"
                                                className="flex items-center px-6 py-3 text-gray-900 whitespace-nowrap dark:text-white"
                                                >
                                                    <div className="bank_info" >
                                                        <div className="font-normal text-gray-500 mt-1">
                                                            <img src={ayande} alt="بانک آینده" />
                                                            بانک آینده
                                                        </div>
                                                    </div>
                                                </th>
                                                <td className="px-6 py-3 number">
                                                0203832770006
                                                </td>
                                                <td className="px-6 py-3 number">
                                                IR50-0620-0000-0020-3832-7700-06
                                                </td>
                                            </tr>

                                            <tr
                                                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 order_success"
                                            >
                                                <th
                                                scope="row"
                                                className="flex items-center px-6 py-3 text-gray-900 whitespace-nowrap dark:text-white"
                                                >
                                                    <div className="bank_info" >
                                                        <div className="font-normal text-gray-500 mt-1">
                                                            <img src={mellat} alt="بانک ملت" />
                                                            بانک ملت
                                                        </div>
                                                    </div>
                                                </th>
                                                <td className="px-6 py-3 number">
                                                8971191636
                                                </td>
                                                <td className="px-6 py-3 number">
                                                IR25-0120-0100-0000-8971-1916-36
                                                </td>
                                            </tr>

                                            <tr
                                                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 order_success"
                                            >
                                                <th
                                                scope="row"
                                                className="flex items-center px-6 py-3 text-gray-900 whitespace-nowrap dark:text-white"
                                                >
                                                    <div className="bank_info" >
                                                        <div className="font-normal text-gray-500 mt-1">
                                                            <img src={shahr} alt="بانک شهر" />
                                                            بانک شهر
                                                        </div>
                                                    </div>
                                                </th>
                                                <td className="px-6 py-3 number">
                                                7001000966730
                                                </td>
                                                <td className="px-6 py-3 number">
                                                IR17-0610-0000-0700-1000-9667-30
                                                </td>
                                            </tr>

                                            <tr
                                                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 order_success"
                                            >
                                                <th
                                                scope="row"
                                                className="flex items-center px-6 py-3 text-gray-900 whitespace-nowrap dark:text-white"
                                                >
                                                    <div className="bank_info" >
                                                        <div className="font-normal text-gray-500 mt-1">
                                                            <img src={keshavarzi} alt="بانک کشاورزی" />
                                                            بانک کشاورزی
                                                        </div>
                                                    </div>
                                                </th>
                                                <td className="px-6 py-3 number">
                                                897075622
                                                </td>
                                                <td className="px-6 py-3 number">
                                                IR97-0160-0000-0000-0897-0756-22
                                                </td>
                                            </tr>
                                        </tbody>
                            </table>
                    </div>
                    </div>
                </div>
            </div>
        </>
       
        
    );
}

export default ManualPayment;
