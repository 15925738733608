import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Sidebar from "../sidebar";
import Navbar from "../navbar";
import ManualPayment from '../manualpayment';
import StatusDiv from '../../utils/status';
import { timeAgo } from '../../utils/timeAgo';

// toast lib ==>
import { Toast } from 'primereact/toast';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primereact/resources/primereact.min.css';

import CryptoJS from 'crypto-js';

// Dialog ==>
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

const Admin = () => {
    const Auth_Cookie = Cookies.get('avaAuth');
    const [isLoading, setIsLoading] = useState(true);
    const [isValidCookie, setIsValidCookie] = useState(false);
    const toast = useRef(null);
    const [users, setUsers] = useState([]);
    const [payment, setPayment] = useState([]);
    const [showPayment, setShowPayment] = useState(false);
    const [Role, setRole] = useState("user");
    const user_role = Cookies.get("theme");
    const [InnerLoader, setInnerLoader] = useState(false);
    // add order ==>
    const [amount, setAmount] = useState('');
    const navigate = useNavigate();
    // searchbar ==>
    const [searchBy, setSearchBy] = useState("");
    const [searchValue, setSearchValue] = useState("");
    // loader ==>
    const [miniLoader, setMiniLoader] = useState(false);
    // Admin dialoge ==>
    const [visibleUser, setVisibleUser] = useState(false);
    const [userPaymentData, setUserPaymentData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [status,setStatus] = useState("");
    const [visibleRejectReason,setVisibleRejectReason] = useState(false);
    const [message, setMessage] = useState("");
    const [messageDialoge, setMessageDialoge] = useState(false);

    const decrypt = (ciphertext) => {
        const bytes = CryptoJS.AES.decrypt(ciphertext, process.env.REACT_APP_SECRET_KEY);
        return bytes.toString(CryptoJS.enc.Utf8);
    };

    const footerUserDialoge = (
        <div className="grid grid-cols-2 gap-5" >
            {userData['Identify'] != 1 ? (
                <>
                    <Button label="تایید احرازهویت" type="submit" onClick={() => UserAction(userData['id'],null,1,null)} icon="pi pi-check" autoFocus />
                    <Button label="رد احرازهویت" type="submit" onClick={() => setMessageDialoge(true)} icon="pi pi-check" autoFocus />
                </>
            )  : (
                <></>
            )}
            {userData['Suspend'] != 1 ? (
                <Button label="مسدودسازی" type="submit" onClick={() => UserAction(userData['id'],1,null,null)} icon="pi pi-check" autoFocus />
            )  : (
                <Button label="فعالسازی" type="submit" onClick={() => UserAction(userData['id'],0,null,null)} icon="pi pi-check" autoFocus />
            )}
            <Button label='انصراف' icon="pi pi-times"  onClick={() => setVisibleRejectReason(true)}  className="p-button-text" />
        </div>
    );

    const fetchUsers = async () => {
        try {
          // Mocking API call to validate cookie, replace with actual API call
          const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/public/api/v1/users/user.php`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Auth_Cookie}`,
              },
            },
          );
  
          if (response.ok) {
            const data = await response.json();
            if (data.status == 200) {
              setUsers(data.message);
              setIsValidCookie(true);
              setIsLoading(false);
            } else {
              if (data.status == 403) {
                Cookies.remove("avaAuth");
                navigate("/login");
              } else {
                toast.current.show({
                  severity: "error",
                  summary: "Error",
                  detail: data.message,
                  life: 5000,
                });
              }
            }
          } else {
            navigate("/login");
          }
        } catch (error) {
          navigate("/login");
        } finally {
          setIsLoading(false);
        }
    };


    useEffect(() => {
        if (!Auth_Cookie) {
            navigate('/login');
            return; // Exit early if no cookie is present
        }
        fetchUsers();
    }, [navigate]);


    useEffect(() => {
        if (decrypt(user_role) == 'user') {
            setRole('user')
        } else if(decrypt(user_role) == 'admin'){
            setRole('admin')
        }else{
            setRole('user')
        }
    }, [Role, setRole]);
    
    const UserAction = async (UserId,Suspend,Identify,Message) => {
        try {
            setInnerLoader(true);
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/public/api/v1/users/user.php?` +
              `UserId=${encodeURIComponent(UserId)}&` +
              `Suspend=${encodeURIComponent(parseInt(Suspend))}&` +
              `Identify=${encodeURIComponent(parseInt(Identify))}&` +
              `Message=${Message}`
            ,
            {
                method: "PUT",
                headers: {
                  'Authorization': `Bearer ${Auth_Cookie}`,
                },
                redirect: "follow",
              },
            );
    
            const data = await response.json();
            if (data.status === 200) {
               window.location.reload();
            } else {          
                alert(data.message)
                setInnerLoader(false);
            }
    
        } catch (error) {
            if (error instanceof Error) {
                console.log(error.message);
            } else {
                console.log('An unexpected error occurred');
            }
        }
    };

    const serachAction = async (e) => {

        e.preventDefault();
        setMiniLoader(true);
    
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/public/api/v1/users/user.php?SearchBy=${searchBy}&Value=${searchValue}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              'Authorization': `Bearer ${Auth_Cookie}`,
            },
            redirect: "follow",
          },
        );
    
        const data = await response.json()
        if (data.status === 200) {
            setUsers(data.message);
            setMiniLoader(false);
        } else {          
            alert(data.message)
        }
    };

    // get order data for payment details modal ==>
    const openUserData = (user) => {
    // setUserPaymentData(payment);
        setUserData(user);
      //  getPayment(order['id'])
        setVisibleUser(true)
    };

    
    return  (
        <>
        {InnerLoader != "" ? (
            <div className="loading_wrapper">
                <span className="loader"></span>
            </div>
        )  : (
            <></>
        )}   
        <Toast ref={toast} />
        <div className="lg:flex" >
            <Sidebar/>
            <div className="mainbar" >
                <Navbar/>
                <div className="main">
                    {showPayment ? (
                        <ManualPayment paymentData={payment} />
                    )  : (
                        <></>
                    )}
                    <div className="single_page_title">
                        
                        <h1>مدیریت کاربران</h1>
                        <h2>users</h2>

                        <div className="mr-auto"><i className="bx bx bx-user li_icon"></i></div>
                    </div>
                    <div >
                        <div className="mt-5 pro_searchbar">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"><b>جستجو پیشرفته</b></label>
                            <form className='flex items-center' onSubmit={serachAction} >
                                <div className="custom-select-wrapper">
                                    <select 
                                    required
                                    value={searchBy}
                                    onChange={(e) => setSearchBy(e.target.value)}
                                    className="ml-4 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom custom-select">
                                        <option selected>جستجو براساس</option>
                                        <option value="Email">ایمیل</option>
                                        <option value="id">شناسه کاربری</option>
                                        <option value="Hesab">شماره حساب</option>
                                        <option value="Sheba">شماره شبا</option>
                                        <option value="Mobile">شماره تماس</option>
                                        <option value="LastName">نام خانوادگی</option>
                                    </select>
                                </div>
                                <input 
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                                placeholder="متن جستجو + اینتر"  required type="text" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom"  />
                                
                                {miniLoader ? (
                                    <div className="mini_loader pt-7">
                                        <div className="loader_small"></div>
                                    </div>
                                )  : (
                                    <>
                                        <button className='button mr-4' type='submit' >جستجو</button>
                                        <i onClick={fetchUsers} class='bx bx-sync reset_data mr-3' ></i>
                                    </>
                                )}
                                
                                
                            </form>
                        </div>
                        {/* <div className="search_area">
                            {Role == "admin" ? (
                                <h1>search</h1>
                            )  : (
                                <></>
                            )}
                        </div> */}
                        <div className="lg:flex gap-5">
                            <div className="card p-5 mt-6 w-full">
                                <div className="table_scroll">
                                    {users.length > 0 ? (
                                    <>
                                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                                <tr>
                                                    <th scope="col" className="px-6 py-3">
                                                        پست الکترونیکی
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        نام کامل
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                       شماره همراه
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        کدملی
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        تاریخ عضویت
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        مسدودسازی
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        احرازهویت
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        بیشتر
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {users.map((user, index) => (
                                                <tr
                                                    key={index}
                                                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 order_success"
                                                >
                                                    <td className="px-6 py-4">
                                                    {user["Email"]}
                                                    </td>
                                                    <td className="px-6 py-3">
                                                    {user["FirstName"]}
                                                    <span> - </span>
                                                    {user["LastName"]}
                                                    </td>
                                                    <td className="px-6 py-3 number">
                                                    {user["Mobile"]}
                                                    </td>
                                                    <td className="px-6 py-3">
                                                    {user["NationalCode"]}
                                                    </td>
                                                    <td className="px-6 py-3">
                                                    {timeAgo(user["RegisterDate"])}
                                                    </td>
                                                    <td className="px-6 py-3">
                                                    <StatusDiv statusCode={user["Suspend"]} />
                                                    </td>
                                                    <td className="px-6 py-3">
                                                    <StatusDiv statusCode={user["Identify"]} />
                                                    </td>
                                                    <td>
                                                        <button className="button mini_button" onClick={() => openUserData(user)} >
                                                            عملیات
                                                        </button>
                                                    </td>

                                                </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </>

                                    )  : (
                                        <div className="mini_loader">
                                            <div className="loader_small"></div>
                                        </div>
                                    )}
                                    
                                </div>

                           </div>
                        
                        </div>

                        <Dialog header="جزییات کاربر" visible={visibleUser} className="dialoge_box big_dialoge" onHide={() => {if (!visibleUser) return; setVisibleUser(false); }} footer={footerUserDialoge}>

                            
                            <div className="mb-5 lg:w-1/2 lg:inline-block lg:pl-3">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">نام</label>
                                <input 
                                value={userData['FirstName']}
                                disabled
                                readOnly
                                type="text" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom" placeholder="نام کوچک" required />
                            </div>
                            <div className="mb-5 lg:w-1/2 lg:inline-block lg:pr-3">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">نام خانوادگی</label>
                                <input 
                                value={userData['LastName']}
                                disabled
                                readOnly
                                type="text" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom" placeholder="نام خانوادگی" required />
                            </div>
                            <div className="mb-5 lg:w-1/2 lg:inline-block lg:pl-3">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">شماره همراه</label>
                                <input 
                                value={userData['Mobile']}
                                disabled
                                readOnly
                                type="tel"  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom" placeholder="09121234567" dir='rtl' required />
                            </div>
                            <div className="mb-5 lg:w-1/2 lg:inline-block lg:pr-3">
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">پست الکترونیک</label>
                                <input 
                                value={userData['Email']}
                                disabled
                                readOnly
                                type="email" id="email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom" placeholder="example@email.com" required />
                            </div>
                            <div className="mb-5">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">کد ملی</label>
                                <input 
                                value={userData['NationalCode']}
                                disabled
                                readOnly
                                type="text" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom" placeholder="example@email.com" required />
                            </div>
                            <div className="mb-5">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">شماره حساب</label>
                                <input 
                                value={userData['Hesab']}
                                disabled
                                readOnly
                                type="text" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom" placeholder="example@email.com" required />
                            </div>
                            <div className="mb-5">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">شماره شبا</label>
                                <input 
                                value={userData['Sheba']}
                                disabled
                                readOnly
                                type="text" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom" placeholder="example@email.com" required />
                            </div>

                            <div className="mb-5">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">توضیحات</label>
                                <input 
                                value={userData['Message']}
                                disabled
                                readOnly
                                type="text" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom" placeholder="example@email.com" required />
                            </div>

                            <a className="button mini_button w-full block py-4 mb-7" target="_bank" href={ process.env.REACT_APP_BASE_URL + '/public/api/v1/file/uploads/' + userData['Image']}>
                               تصویر احراز هویت
                            </a>
                        
                        </Dialog>     
                        
                        <Dialog header="دلیل رد احرازهویت" visible={messageDialoge} className="dialoge_box big_dialoge" onHide={() => {if (!messageDialoge) return; setMessageDialoge(false); }}>

                            <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white mb-2 block">توضیحات برای کاربر</label>
                            <textarea 
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            id="message" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="بنویسید ..."></textarea>
                            <button type="button" className="button w-full mt-5 mb-5" onClick={() => UserAction(userData['id'],null,0,message)} >ثبت و ارسال</button>

                        </Dialog>                              
                    </div>

                </div>
            </div>
        </div>
    </>

    ) 
};

export default Admin;
