
import React, { useEffect, useState, useRef } from "react";
import Cookies from 'js-cookie';

// bank logos =>
// import ayande from '../images/bank_ayande.svg';
// import keshavarzi from '../images/bank_keshavarzi.svg';
// import mellat from '../images/bank_mellat.svg';
// import parsian from '../images/bank_parsian.svg';
// import saman from '../images/bank_saman.svg';
// import shahr from '../images/bank_shahr.svg';
// import { timeAgo } from '../utils/timeAgo';

// toast lib ==>
import { Toast } from "primereact/toast";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primereact/resources/primereact.min.css";
import { data } from "autoprefixer";


const Withdraw = () => {

    const Auth_Cookie = Cookies.get('avaAuth');
    const [balance, setBalance] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [fileName, setFileName] = useState("");
    const [image, setImage] = useState(null);
    const [InnerLoader, setInnerLoader] = useState(false);
    const [trackingCode, setTrackingCode] = useState("");
    const [amount, setAmount] = useState("");
    const [type, setType] = useState("");
    const [gateway, setGateway] = useState("Manual");

    const toast = useRef(null);
    const inputValue = balance == null ? '0' : parseInt(balance).toLocaleString();

    useEffect(() => {

        const fetchBalance = async () => {
            try {
              // Mocking API call to validate cookie, replace with actual API call
              const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/public/api/v1/users/user.php`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${Auth_Cookie}`,
                  },
                },
              );
      
              if (response.ok) {
                const data = await response.json();
                if (data.status == 200) {
                    setBalance(data.message[0].Balance)
                } else {
                    alert(data.status)
                }
              } else {
                alert(data.status)
              }
            } catch (error) {
              console.log(error)
            } 
          };

          fetchBalance();
    }, []);
    
    
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImage(file);
            setPreviewUrl(URL.createObjectURL(file));
            setFileName(file.name);
        } else {
            setImage(null);
            setPreviewUrl(null);
            setFileName("");
        }
    };

    const isWithinTwentyMinutes = (timestamp) => {
        const now = Date.now();
        const timestampMs = parseInt(timestamp) * 1000;
        const differenceInMilliseconds = now - timestampMs;
        const differenceInMinutes = differenceInMilliseconds / 1000 / 60;
        return differenceInMinutes <= 20;

    };


    const CreateWithdraw = async (e) => {

        if(trackingCode != null){
            e.preventDefault();
            setInnerLoader(true);
            try {
              const formData = new FormData();
              
              formData.append('Amount', amount);
              formData.append('Type', 'Withdraw');
              formData.append('Gateway', gateway);
              formData.append('Currency', "TOMAN");

              const requestOptions = {
                  method: 'POST',
                  headers: {
                      'Authorization': `Bearer ${Auth_Cookie}`,
                  },
                  body: formData,
                  redirect: 'follow'
              };
    
              const response = await fetch( process.env.REACT_APP_BASE_URL + '/public/api/v1/payments/payment.php', requestOptions);
              const data = await response.json();

              if (data.status === 200) {
                    window.location.reload();
                } else {
                    setInnerLoader(false);
                    alert(data.message)
                }
            } catch (error) {
                console.log(error);
                setInnerLoader(false);
            }
        }else{
            toast.current.show({ severity: 'error', summary: 'خطا', detail: 'کد پیگیری را وارد کنید' , life: 5000 });
        }

    };

    return InnerLoader ? (
            <>
                <Toast ref={toast} />
                <div className="loading_wrapper">
                    <span className="loader"></span>
                </div>
            </>
        )  : ( 
        <div className='card p-7 mt-6 w-full mb-5' >
            <Toast ref={toast} />
            <div className="hint_box flex items-center mb-7">
                <i className="pi pi-receipt" style={{ fontSize: '1.5rem' }}></i>
                <div  className="pr-5" >
                    <b className="mb-2 block" >برداشت از کیف پول</b>
                    <span className='flex mb-2' >پس از ثبت درخواست وضعیت درخواست شما به "درانتظار تایید" تغییر خواهد کرد</span>
                    <span className='flex' >مبلغ موردنظر به شماره حساب ثبت شده در بخش "اطلاعات کاربری" وایز می گردد.</span>
                </div>
            </div>            
            {/* <div className="sent_image mb-5">پرداخت حساب به حساب</div> */}
            <div className="lg:flex gap-5">
                <div className="w-full">                            

                            <form onSubmit={CreateWithdraw}>

                                <div className="mb-5 lg:w-1/2 lg:inline-block lg:pl-3">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        مقدار
                                        <small className="mr-2" >TOMAN</small>
                                    </label>
                                    <input
                                    dir="rtl"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                    type="tel" className="number shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom" required />
                                </div>

                                <div className="mb-5 lg:w-1/2 lg:inline-block lg:pr-3">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        موجودی قابل برداشت
                                        <small className="mr-2" >TOMAN</small>
                                    </label>
                                    {balance != null ? (
                                        <input
                                        value={parseInt(balance).toLocaleString()}
                                        readOnly
                                        disabled
                                        type="text" className="number shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom" required />
                                    )  : (
                                        <div className="mini_loader">
                                            <div className="loader_small"></div>
                                        </div>
                                    )}

                                </div>

                                <ul className="final_payment flex items-center">
                                    <li>
                                    مبلغ نهایی:
                                    </li>

                                    <li className='mr-auto' > 
                                                <b>تومان</b>
                                                <span className='number' >{inputValue}</span>
                                        {/* {isWithinTwentyMinutes(paymentData['Amount']) != false ? (
                                            <>
                                                <b>تومان</b>
                                                <span className='number' >{parseInt(paymentData['Price']).toLocaleString()}</span>
                                            </>
                                        )  : (
                                            <button className="button" onClick={updateRate} >
                                                بروزرسانی نرخ
                                                <i className="pi pi-replay mr-4"></i>
                                            </button>
                                        )}
                                        */}

                                    </li>
                                </ul>

                                <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 w-full mt-5 button">ثبت درخواست</button>
                            </form>
                </div>
            </div>
        </div>
    );
}

export default Withdraw;
